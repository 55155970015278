import React, { useEffect, useState } from 'react';
import { useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";
import logo from '../assets/img/logo_90_anos_rojo.svg';
import link_icon from '../assets/img/link_icon.svg';
import lupa from '../assets/img/magnifier_grey_icon.svg';
import usuario from '../assets/img/user_icon_mobile.svg';
import backArrow from '../assets/img/arrow_back_blue.svg';
import menuIcon from '../assets/img/MENU.svg';
import closeIcon from '../assets/img/cross-icon.svg';
import caret from '../assets/img/caret_up.svg';
import Modal from 'react-modal';
import Search from './Search';
import { getPath } from '../utils/getPath.js';
import '../styles/nav-bar.scss';
import '../styles/mobile-menu.scss';

const NavBarMobile = (props) => {
  const { isAuthenticated, signinRedirect, signoutRedirect, removeUser } = useAuth();
  const [ modalIsOpen, setIsOpen ] = useState(false);
  const [ displayMenu, setDisplayMenu ] = useState(false);
  const [ displaySubMenu, setDisplaySubMenu ] = useState(false);
  const [ mobileMenu, setMobileMenu ] = useState(null);
  const [ mobileSubMenu, setMobileSubMenu ] = useState(null);
  const [ userOpen, setUserOpen ] = useState(false)

  const menu = props.menu;
  const showMyAccount = props.showMyAccount;
  
  function openModal() {
    setIsOpen(true);
  }

  function closeModal(){
    setIsOpen(false);
  }

  function generateLeavesMenu(key, secondKey){
    if(menu[key].children[secondKey].hidelowres)
      return

    return(
      <div className="leaves-wrapper">
        <div className="subtitle">{secondKey}</div>
        {
          Object.keys(menu[key].children[secondKey].children).map(function(thirdKey){
            if(menu[key].children[secondKey].children[thirdKey].published && !menu[key].children[secondKey].children[thirdKey].hidelowres){
              return (
                <div className="leaves">
                  {
                    menu[key].children[secondKey].children[thirdKey].type !== 'external-link' &&
                    <Link  onClick={() => {setDisplayMenu(false); setDisplaySubMenu(false); setUserOpen(false);}} to={getPath(key, secondKey, thirdKey)}>{thirdKey}</Link>
                  }
                  {
                    menu[key].children[secondKey].children[thirdKey].type === 'external-link' &&
                    <a href={menu[key].children[secondKey].children[thirdKey].componentUrl} target="_blank" rel="noopener noreferrer">
                      { thirdKey }
                      <img className="link-icon" src={link_icon} alt="link-icon" />
                    </a>
                  }
                </div>
              )
          }
          })
        }
      </div>
    )
  }

  const handleLogout = () => {
    removeUser()
    signoutRedirect()
  }

  function generateSubMenu(key){
    setMobileSubMenu(
      <div>
        <div className="title">
          <button className="close-mobile-submenu-button" onClick={() => {setDisplayMenu(true); setDisplaySubMenu(false)}}>
            <img src={backArrow} />
          </button><span>{key}</span>
        </div>
        <div>
          {
            Object.keys(menu[key].children).map(function(secondKey){
              return generateLeavesMenu(key, secondKey)
            })
          }
        </div>
      </div>
    )
    setDisplayMenu(false)
    setDisplaySubMenu(true)
  }


  function toggleMenu(key){
    setMobileMenu(
      <li>
        {
          Object.keys(menu).map(function(key){
            if(menu[key].published && key !== 'Administración' && !menu[key].hidelowres){
              return(<ul onClick={() => generateSubMenu(key)}>{key} <div className="menu-carret"> <img className="caret-icon" src={caret} alt="caret-icon" /> </div> </ul>)
            }
          })
        }
      </li>
    )
    setDisplayMenu(!displayMenu)
  }

  return (
    <div className='header-content-mobile'>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} ariaHideApp={false} >
        <Search closeModal={closeModal} mobile />
      </Modal>

      <div className='nav-bar'>
        <div className="middle-wrapper">
          <Link to='/' className="link-logo"><img className='logo' alt='copec-logo' src={logo} /></Link>

          { !displayMenu && !displaySubMenu && !userOpen &&
            <div className='actions'>
              <img src={lupa} alt='lupa' onClick={openModal} />
              <img src={usuario} alt='usuario' onClick={() => setUserOpen(true)} />
              <img src={menuIcon} alt='menu' onClick={() => toggleMenu('all')} />
            </div>
          }

          {
            (displayMenu || displaySubMenu || userOpen) &&
            <button className="close-mobile-menu-button" onClick={() => {setDisplayMenu(false); setDisplaySubMenu(false); setUserOpen(false)}}><img src={closeIcon} /></button>
          }
          {
            userOpen &&
            <div className="dropdown-menu" timeout={900}>
              <div className="row users-menu">
                { showMyAccount &&
                  <div className="column">
                    <Link to="/mi-cuenta" onClick={() => setUserOpen(false)} className="profile-link">Mi cuenta</Link>
                  </div>
                }
                <div className="column">
                  { isAuthenticated &&
                    <button className="close-session-button" onClick={handleLogout}>Cerrar sesión</button>
                  }
                  {!isAuthenticated &&
                    <button className="close-session-button" onClick={signinRedirect}>Log in</button>
                  }
                </div>
              </div>
            </div>
          }
        </div>

        {
          displayMenu &&
          <div className='mobile-menu'>
            { mobileMenu }
          </div>
        }

        {
          displaySubMenu &&
          <div className='mobile-sub-menu'>
            { mobileSubMenu }
          </div>
        }
      </div>
    </div>
  );
};

export default NavBarMobile;
