import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { getPath } from "../utils/getPath.js";
import "../styles/floating-side-bar.scss";
import closeIcon from "../assets/img/cross-icon-menu.svg";
import caretDown from "../assets/img/caret-down-blue.svg";
import logo from "../assets/img/logo_90_anos_rojo.svg";

const EXPANDING_SECTION_CONTENT = "expanding-section__content";

const FloatingSideBar = (props) => {
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [expandedMenuSection, setExpandedMenuSection] = useState();
  const openedMenu = useRef(false);
  const menuSectionsHeight = useRef({});
  const title = props.menuKey;
  const submenu = props.menuSidebar;

  function closeSideBar() {
    setSideBarOpen(false);
    setExpandedMenuSection();
  }

  function openSideBar() {
    openedMenu.current = true;
    setSideBarOpen(true);
  }

  function toggleMenuSection(index) {
    return (e) => {
      const key = e.currentTarget.dataset["section"];
      const height = menuSectionsHeight.current[key];
      if (!height) {
        const content = document.querySelector(
          `.${EXPANDING_SECTION_CONTENT}[data-section="${key}"]`
        );
        menuSectionsHeight.current[key] = content.scrollHeight;
      }

      if (index === expandedMenuSection) {
        setExpandedMenuSection();
      } else {
        setExpandedMenuSection(index);
      }
    };
  }

  return (
    <>
      <button className="floating-side-bar-trigger" onClick={openSideBar}>
        <span>{ title }</span>
        <svg
          className="floating-side-bar-trigger__icon"
          xmlns="http://www.w3.org/2000/svg"
          height="24px"
          viewBox="0 0 24 24"
          width="24px"
        >
          <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
          <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
        </svg>
      </button>
      <button
        onClick={closeSideBar}
        className={`floating-side-bar-backdrop ${sideBarOpen ? "open" : ""} ${
          openedMenu.current && !sideBarOpen ? "close" : ""
        }`}
        tabIndex={sideBarOpen ? 0 : -1}
      />
      <div
        className={`floating-side-bar-menu ${sideBarOpen ? "open" : ""}`}
        tabIndex={sideBarOpen ? 0 : -1}
      >
        <button
          className="floating-side-bar-menu__close"
          onClick={closeSideBar}
        >
          <img src={closeIcon} alt="" />
        </button>
        <div className="floating-side-bar-menu__logo-section">
          <img src={logo} alt="" />
        </div>
        <div className="floating-side-bar-menu__links-section">
          <h1 className="floating-side-bar-menu__links-section__title">
            { title }
          </h1>
          <div className="floating-side-bar-menu__links-section__content">
            {Object.keys(submenu).map((key, index) => (
              <>
              {
                submenu[key].published &&
                  <div className="expanding-section" key={key}>
                    <button
                      className={`expanding-section__title ${
                        expandedMenuSection === index ? "open" : ""
                      }`}
                      onClick={toggleMenuSection(index)}
                      data-section={key}
                    >
                      <span>{key}</span>
                      <img src={caretDown} />
                    </button>
                    <div
                      className="expanding-section__content"
                      data-section={key}
                      style={{
                        maxHeight:
                          expandedMenuSection === index
                            ? menuSectionsHeight.current[key]
                            : 0,
                      }}
                    >
                      {Object.keys(submenu[key].children).map((menukey) => (
                        <>
                          { submenu[key].children[menukey].published &&
                            <NavLink
                              className="expanding-section__content__link"
                              to={getPath(title, key, menukey)}
                              key={menukey}
                              activeClassName="active"
                            >
                              {menukey}
                            </NavLink>
                          }
                        </>
                      ))}
                    </div>
                  </div>
                }
                </>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FloatingSideBar;
