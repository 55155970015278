import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from '../assets/img/logo_90_anos_blanco.svg';
import facebook from '../assets/img/facebook_icon.svg';
import whatsappFondo from '../assets/img/footer-whatsapp-fondo.svg';
import contact from '../assets/img/footer-contact.svg'
import mail from '../assets/img/footer-mail.svg';
import phone from '../assets/img/footer-phone.svg';
import whatsapp from '../assets/img/footer-whatsapp.svg';
import gerenciaEds from '../assets/img/gerencia_eds_icon.png';
import conectados from '../assets/img/conectados_icon.png';
import gerenciaEdsMobile from '../assets/img/gerencia_eds_icon_mobile.png';
import conectadosMobile from '../assets/img/conectados_icon_mobile.png';
import Collapsible from 'react-collapsible';
import '../styles/footer.scss';

const Footer = () => {
  const [width, setWidth] = useState(window.innerWidth);
  //const breakpoint = 768;
  const breakpoint = process.env.REACT_APP_MOBILE_BREAK_POINT;
  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener("resize", handleWindowResize);

    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <div className='footer'>
      <div className='footer-content'>
        <div className='left-side'>
          <img className='logo' src={logo} alt='logo' />
          <div className='info'> 
            <p>Oficinas Generales: Isidora Goyenechea</p>
            <p>2915, Las Condes, Santiago.</p>
          </div>
          <div className='rrss'>
            <a className='rrss-facebook' href="https://es-la.facebook.com/MundoCopec/" target="_blank" rel="noopener noreferrer"><img src={ facebook } alt='icono-facebook' /></a>
            <a className='rrss-whatsapp' href="https://api.whatsapp.com/send?phone=56934426639" target="_blank" rel="noopener noreferrer"><img src={ whatsappFondo } alt='icono-whatsapp' /></a>
          </div>
        </div>
        <Collapsible
          trigger="Canales de atención"
          className="right-side"
          openedClassName="right-side"
          triggerClassName="section-title"
          triggerOpenedClassName="section-title"
          open={width > breakpoint}
          triggerDisabled={width > breakpoint}
        >
          <div className="footer-info">
            <img src={contact} />
            <div className='footer-info-text'>600 200 0202</div>
          </div>
          <div className="footer-info">
            <img src={whatsapp} />
            <a className='rrss-whatsapp' href="https://api.whatsapp.com/send?phone=56934426639" target="_blank" rel="noopener noreferrer">
              +569 34426639
            </a>
          </div>
          <div className="footer-info">
            <img className="footer-mail" src={mail} />
            <a href="mailto:copecenlinea@copec.cl">copecenlinea@copec.cl</a>
          </div>
        </Collapsible>
        <Collapsible
          trigger="Nosotros"
          className="right-side"
          openedClassName="right-side"
          triggerClassName="section-title"
          triggerOpenedClassName="section-title"
          open={width > breakpoint}
          triggerDisabled={width > breakpoint}
        >
          <a href="https://ww2.copec.cl/nuestra-empresa" target="_blank" rel="noopener noreferrer">Quiénes Somos</a>
          <a href="https://ww2.copec.cl/nuestra-empresa/gobierno-corporativo" target="_blank" rel="noopener noreferrer">Gobierno Corporativo</a>
          <a href="https://ww2.copec.cl/nuestra-empresa/canal-de-reporte-etico" target="_blank" rel="noopener noreferrer">Canal Reporte Ético</a>
          <a href="https://ww2.copec.cl/stations" target="_blank" rel="noopener noreferrer">Red EDS</a>
        </Collapsible>
        <div className='bottom'>
          { width > breakpoint && <hr /> }
          <div className="copyright">
            <p>
              <span>@Copyright 2019 Compañía de Petróleos de Chile Copec S.A. Todos los derechos reservados </span>
              {width <= breakpoint && <br></br>}
              <a href="https://ww2.copec.cl/terms#sitio-web" className='tyc' target="_blank" rel="noopener noreferrer">
                {width > breakpoint && "/ "}Términos y Condiciones
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

